import styled from 'styled-components'
import { space, position, typography, color, border, display } from 'styled-system'

const Text = styled.p`
  font-size: inherit;
  font-weight: ${(props) => props.fw || 'none'};
  text-transform: ${(props) => props.textTransform || 'none'};
  line-height: ${(props) => (props.isLabel ? 1.33 : props.isValue ? 1.36 : 'inherit')};
  ${display};
  ${color};
  ${space};
  ${typography};
  ${border};
  ${position};
`
export default Text
