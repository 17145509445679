import React from 'react'
import VerifyBVN from 'agents/panels/List.VerifyBVN'
import { useParams } from 'react-router-dom'
import { queryCache, useMutation } from 'react-query'
import Box from 'design/elements/Box'
import { saveBvn } from 'agents/agents.api.client'
import { loading } from 'lib/formatters'
import Button from 'design/elements/Button'
import { useHistory } from 'react-router-dom'
import Spacer from 'design/elements/Spacer'

const KycVerifyBVNWrapper = (notify) => {
  const { agentId } = useParams()
  const history = useHistory()
  const [mutate, { status: saveStatus }] = useMutation(saveBvn, {
    onSuccess() {
      notify(`Operation successful`)
      queryCache.removeQueries('agent_details')
    },
    onError() {
      alert(`There was a problem performing your request`)
    },
  })
  const saveAgentBVN = React.useCallback(
    (details) => {
      mutate({ ...details, agent_id: +agentId })
    },
    [mutate, agentId]
  )
  return (
    <Box>
      <Button onClick={() => history.goBack()} small variant="default">
        &larr; Back
      </Button>
      <Spacer mt="md" />
      <Box width="25%" maxWidth="300px">
        <VerifyBVN doSave saveFn={saveAgentBVN} saving={loading(saveStatus)} />
      </Box>
    </Box>
  )
}

export default KycVerifyBVNWrapper
