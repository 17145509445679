import styled from 'styled-components'
import { space, typography, color, flexbox, layout, position, grid, border, background, shadow } from 'styled-system'
const Box = styled.div`
  border-radius: ${(props) => (props.curved ? '5px' : 0)};
  font-size:${(props) => (props.fs ? '14px' : 'inherit')};
  ${space};
  ${typography};
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${grid};
  ${border};
  ${background};
  ${shadow};
`
export default Box
