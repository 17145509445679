import React from 'react'
import Panel from 'design/elements/Panel'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'
import FieldText from 'design/elements/FieldText'
import Box from 'design/elements/Box'
import Spacer from 'design/elements/Spacer'
import Button from 'design/elements/Button'
import { manuallyVerifyBvn } from 'agents/agents.api.client'
import { useMutation } from 'react-query'
import { loading } from 'lib/formatters'
import { useToast } from '../../toast/ToastContext'
import ImageUploader from 'design/elements/ImageUploader'
import Checkbox from 'design/elements/Checkbox'

const stages = { INPUT: 'input', DETAILS: 'details' }

export default function VerifyBVN({ doSave = false, saveFn, saving }) {
  const [bvn, setBvn] = React.useState('')
  const [bvnFullName, setBvnFullName] = React.useState('')
  const [bvnPhoneNumber, setBvnPhoneNumber] = React.useState('')
  const [selectedDay, setSelectedDay] = React.useState('')
  const [passport, setPassPort] = React.useState('')
  const [agentBvnVerificationStatus, setAgentBvnVerificationStatus] = React.useState(false)
  const [agentBvnRecordMatch, setAgentBvnRecordMatch] = React.useState(false)

  const url = window.location.href
  const agentId = url.split('/')[4]

  const [stage, setStage] = React.useState(stages.INPUT)
  const details = React.useRef({})
  const rawResponse = React.useRef({})
  const { alert, notify } = useToast()
  const [mutate, { status }] = useMutation(manuallyVerifyBvn, {
    onSuccess(response) {
      notify(response)
    },
    onError(error) {
      alert(error)
    },
  })

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault()
      mutate({
        Bvn: bvn,
        BvnFullName: bvnFullName,
        BvnPhoneNumber: bvnPhoneNumber,
        Passport: passport,
        AgentId: agentId,
        IsBvnVerified: agentBvnVerificationStatus,
        IsBvnRecordMatch: agentBvnRecordMatch,
      })
    },
    [mutate, bvn, bvnFullName, bvnPhoneNumber, passport, agentId, agentBvnVerificationStatus, agentBvnRecordMatch]
  )

  return (
    <>
      <Panel py="lg" px="md">
        <Text textTransform="uppercase" as="h4" color="highlight" fontSize={12} fontWeight="bold">
          Verify BVN
        </Text>
      </Panel>
      {[stages.INPUT].includes(stage) && (
        <RenderInput
          handleSubmit={handleSubmit}
          setBvn={setBvn}
          bvn={bvn}
          setBvnFullName={setBvnFullName}
          status={status}
          bvnFullName={bvnFullName}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          setPassPort={setPassPort}
          bvnPhoneNumber={bvnPhoneNumber}
          setBvnPhoneNumber={setBvnPhoneNumber}
          agentBvnVerificationStatus={agentBvnVerificationStatus}
          setAgentBvnVerificationStatus={setAgentBvnVerificationStatus}
          setAgentBvnRecordMatch={setAgentBvnRecordMatch}
          agentBvnRecordMatch={agentBvnRecordMatch}
          passport={passport}
        />
      )}
      {[stages.DETAILS].includes(stage) && (
        <RenderDetails
          doSave={doSave}
          onSave={() => saveFn(rawResponse.current)}
          isVerified={rawResponse.current.is_bvn_verified}
          details={details.current}
          goBack={() => setStage(stages.INPUT)}
          saving={saving}
        />
      )}
    </>
  )
}

function RenderInput({
  handleSubmit,
  setBvn,
  bvn,
  status,
  bvnFullName,
  setSelectedDay,
  selectedDay,
  setPassPort,
  setBvnFullName,
  setBvnPhoneNumber,
  bvnPhoneNumber,
  agentBvnVerificationStatus,
  setAgentBvnVerificationStatus,
  setAgentBvnRecordMatch,
  agentBvnRecordMatch,
  passport,
}) {
  const renderCustomInput = () => (
    <input
      type={'date'}
      placeholder="1900-01-01"
      value={selectedDay}
      onChange={(e) => {
        setSelectedDay(e.target.value)
        // form.setValue('date_of_birth', dayjs(e.target.value).format('YYYY-MM-DDTHH:mm:ss.000[Z]'))
      }}
      style={{
        border: '1px solid #dbe0e5',
        backgroundColor: '#ffffff',
        fontFamily: 'inherit',
        height: '40px',
        textIndent: '0.5em',
        color: '#1c3244f2',
        resize: 'none',
        padding: '0',
        fontSize: '12px',
        borderRightColor: '#dbe0e5',
        outline: 'none',
        width: '100%',
      }}
      className="exSHyF" // a styling class
    />
  )

  return (
    <Panel py="md" px="md" as="form" onSubmit={handleSubmit}>
      <TextField required value={bvn} onChange={(e) => setBvn(e.target.value)} label="BVN" placeholder="BVN" />
      <Spacer mt="lg" />
      <TextField
        required
        value={bvnFullName}
        onChange={(e) => setBvnFullName(e.target.value)}
        label="Full Name"
        placeholder="Full name"
      />
      <Spacer mt="lg" />
      <TextField
        required
        label="BVN Phone Number"
        // name="phone_number"
        placeholder="BVN phone number"
        value={bvnPhoneNumber}
        onChange={(e) => setBvnPhoneNumber(e.target.value)}
      />
      {/* <Spacer mt="lg" />
      {renderCustomInput()} */}
      <Spacer mt="lg" />
      {passport ? (
        <Button
          variant="error"
          onClick={(e) => {
            e.preventDefault()
            setPassPort('')
          }}
        >
          Remove passport
        </Button>
      ) : (
        <ImageUploader
          require
          name="PassPort"
          fileName="PassPort"
          placeholder="Upload Agent passport"
          onChange={([file, preview], close) => {
            close && close()
            setPassPort(file)
          }}
        />
      )}
      <Spacer mt="lg" />
      <Checkbox
        inputProps={{
          required: true,
        }}
        label="Is Agent BVN verified"
        defaultChecked={agentBvnVerificationStatus}
        onChange={() => setAgentBvnVerificationStatus((c) => !c)}
        name="agent_bvn_verification_status"
        id="agent_bvn_verification_status"
      />
      <Spacer mt="lg" />
      <Checkbox
        inputProps={{
          required: true,
        }}
        label="Does agent BVN records match?"
        defaultChecked={agentBvnRecordMatch}
        onChange={() => setAgentBvnRecordMatch((c) => !c)}
        name="agent_bvn_record_status"
        id="agent_bvn_record_status"
      />
      <Spacer mt="lg" />
      <Button loading={loading(status)} variant="success" fullWidth>
        Verify
      </Button>
    </Panel>
  )
}

function RenderDetails({ details, goBack, isVerified, onSave, doSave, saving }) {
  return (
    <Panel py="md" px="md">
      {Object.entries(details).map(([key, value], i, all) => (
        <Box key={i}>
          <FieldText isLabel>{key}</FieldText>
          <FieldText isValue>{value}</FieldText>
          {i !== all.length && <Spacer mt="lg" />}
        </Box>
      ))}
      <Button variant="default" fullWidth onClick={goBack}>
        Back
      </Button>

      {doSave && isVerified && (
        <>
          <Spacer mt="lg" />
          <Button variant="success" fullWidth onClick={onSave} loading={saving}>
            SAVE
          </Button>
        </>
      )}
    </Panel>
  )
}
