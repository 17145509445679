import React from 'react'
import UploadButton from 'design/elements/UploadButton'
import Modal from 'design/elements/Modal'
import Button from 'design/elements/Button'
import Spacer from 'design/elements/Spacer'
import Box from 'design/elements/Box'
import Panel from 'design/elements/Panel'
import {useToggle} from 'lib/hooks/useToggle'
import Text from 'design/elements/Text'
import { Label } from './TextField'
import { getRand } from 'lib/formatters'
import { useToast } from '../../toast/ToastContext'

function ImageUploader({ onChange, accept, name, fileName, label, error, placeholder, onCancel, disabled }, ref) {
  const { warn } = useToast()

  const [showDialog, toggleDialog] = useToggle(false)
  const file = React.useRef()
  const changeFunctionRef = React.useRef(onChange)
  const filePreview = React.useRef()
  const randomNumber = React.useRef(getRand())

  const handleFileChange = React.useCallback(
    (e) => {
      const newFile = e.target.files[0]

      if (newFile.size / 1000 > 1024) {
        warn(`The selected file is too large, please select a file less than 1MB to continue`)
        return
      }
      file.current = newFile

      let reader = new FileReader()

      reader.onload = function (evt) {
        filePreview.current = evt.target.result
        toggleDialog(true)
        randomNumber.current = randomNumber.current + getRand()
      }

      reader.readAsDataURL(newFile)
    },
    [toggleDialog, warn]
  )

  const handleSave = React.useCallback(() => {
    changeFunctionRef.current &&
      changeFunctionRef.current([file.current, filePreview.current], function closeModal() {
        toggleDialog(false)
        file.current = null
      })
  }, [toggleDialog])

  const handleCancel = React.useCallback(() => {
    toggleDialog(false)
    file.current = null
    filePreview.current = null
    onCancel && onCancel()
  }, [toggleDialog, onCancel])

  return (
    <Box display="flex" flexDirection="column">
      {file.current && (
        <>
          <Label>
            {label} {error}
          </Label>
          <Spacer mt="1em" />
        </>
      )}
      <UploadButton
        key={randomNumber.current}
        error={Boolean(error)}
        ref={ref}
        onChange={handleFileChange}
        fileName={fileName}
        name={name}
        accept={accept || 'image/*'}
        disabled={disabled}
      >
        {file.current ? `${file.current.size / (1000).toFixed(2)} Kb` : placeholder || 'Upload Image'}
      </UploadButton>
      {showDialog && file.current && (
        <FileDialog
          close={toggleDialog}
          filePreview={filePreview.current}
          onContinue={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Box>
  )
}
function FileDialog({ close, filePreview, onContinue, onCancel }) {
  return (
    <Modal close={close}>
      <Box width="70vw" height="90vh" maxHeight="80em" overflow="hidden" fontSize="sm">
        <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
          <Text color="highlight" fontWeight="bold">
            IMAGE UPLOAD
          </Text>
          <Box mt="md" display="grid" width="40%" ml="auto" gridGap="2.5em" gridTemplateColumns="1fr 1fr">
            <Button variant="text" fullWidth onClick={onCancel} type="button">
              Cancel
            </Button>
            <Button onClick={onContinue} variant="success" fullWidth type="button">
              Continue
            </Button>
          </Box>
        </Panel>
        <Panel px="xl" py="lg" height="90%">
          <Box overflow="auto" height="100%">
            <Box as="img" maxWidth="100%" objectFit="cover" objectPosition="center" src={filePreview} />
          </Box>
        </Panel>
      </Box>
    </Modal>
  )
}

export default React.forwardRef(ImageUploader)
